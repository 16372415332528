import React, { useState } from 'react'
import clsx from 'clsx'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAward } from '@fortawesome/free-solid-svg-icons'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import PaymentModal from '../../components/PaymentModal'
import Markdown from '../../components/Markdown'
import Testimony from '../../components/Testimony'
import BackToTopButton from '../../components/BackToTopButton'
import Link from '../../components/LinkWrapper'
import ScrollOnAnimation from '../../components/ScrollOnAnimation'
import i18n from '../../locales/he.yaml'
import { fireEvent } from '../../analytics'

const course = i18n.courses.nonAcademyCourses.find(
  (course) => course.name === 'איך באמת לתמחר עוגות'
)

const HowToPriceCakesQuery = graphql`
  query HowToPriceCakesQuery {
    image1: file(relativePath: { eq: "עכבר-רטטוי-מפוסל-בבצק-סוכר.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    image2: file(relativePath: { eq: "אריאל-ויונתן-אוכלים-תפוח.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image3: file(relativePath: { eq: "צב-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image4: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-קיץ-של-מיקי-מאוס-וגלשן-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image5: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-מפלצת-סביבתית-מפוסלת-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image6: file(
      relativePath: { eq: "אריאל-ויונתן-הולכים-ביחד-בחוף-הים.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
  }
`

const HowToPriceCakesTemplate = ({ pathname, data, product }) => {
  const [isPaymentForm, setIsPaymentForm] = useState(false)

  const { image1, image2, image3, image4, image5, image6 } = data

  const IMAGES = [image1, image2, image3, null, image4, image5, null, image6]

  const CTA = ({ cta }) => (
    <a
      href='#registration'
      className='button is-nude is-medium'
      onClick={() =>
        fireEvent('InternalClick', product, {
          to: '#registration'
        })
      }
    >
      {cta || course.callToAction}
    </a>
  )

  return (
    <>
      {isPaymentForm && (
        <PaymentModal
          onClose={() => setIsPaymentForm(false)}
          welcomeImage={image4}
          successLabel={course.successLabel}
          course={course}
        />
      )}
      <BackToTopButton selectorId='how-to-price-cakes' />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/academy/'}>{i18n.header.academy}</Link>
                </li>
                <li className='is-active'>
                  <Link to={pathname} aria-current='page'>
                    {course.name}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column is-6 is-full-touch'>
                <h1 className='has-text-nude'>{course.subtitle}</h1>
                <h2 className='is-size-4 is-margin-bottom-6'>{course.title}</h2>
                <p>
                  <CTA cta={course.callToActionOfficial} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {course.bullets.map((bullet, index) =>
        index === 3 || index === 6 ? (
          <ScrollOnAnimation key={index}>
            <div className='hero has-background-nude-light'>
              <div className='hero-body'>
                <div className='container content'>
                  <div className='columns is-centered'>
                    <div className='column is-full-touch is-6'>
                      <div className='is-margin-bottom-4 has-text-centered'>
                        <h3>{bullet.title}</h3>
                      </div>
                      <div className='is-flex is-flex-direction-column is-justify-content-center'>
                        <p className='is-align-self-center'>
                          <Markdown>{bullet.description}</Markdown>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ScrollOnAnimation>
        ) : (
          <div
            key={index}
            className='hero'
            id={index === 1 ? 'how-to-price-cakes' : undefined}
          >
            <div className='hero-body'>
              <div className='container content'>
                <div
                  className={clsx(
                    'columns is-mobile is-multiline is-centered is-vcentered',
                    {
                      'direction-row-reverse':
                        (index - (index > 6 ? 2 : index > 3 ? 1 : 0)) % 2 === 1
                    }
                  )}
                >
                  <div
                    className={clsx(
                      'column is-full-mobile is-8-tablet is-6-desktop',
                      {
                        'is-offset-1-widescreen':
                          (index - (index > 6 ? 2 : index > 3 ? 1 : 0)) % 2 ===
                          1
                      }
                    )}
                  >
                    <ScrollOnAnimation key={bullet.title}>
                      <div className='columns is-flex-direction-column-touch'>
                        <div className='column'>
                          <h4 className='has-text-centered-touch'>
                            {bullet.title}
                          </h4>
                          <p>
                            <Markdown>{bullet.description}</Markdown>
                          </p>
                        </div>
                      </div>
                    </ScrollOnAnimation>
                  </div>
                  <ScrollOnAnimation>
                    <div
                      className={clsx(
                        'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen is-margin-top-4-touch',
                        {
                          'is-offset-1-widescreen':
                            (index - (index > 6 ? 2 : index > 3 ? 1 : 0)) %
                              2 ===
                            0
                        }
                      )}
                    >
                      <GatsbyImage
                        image={getImage(IMAGES[index])}
                        title={bullet.image.title}
                        alt={bullet.image.alt}
                        className='live-image'
                        style={{
                          maxWidth: getImage(IMAGES[index]).width,
                          margin: '0 auto' // used to center the image
                        }}
                        loading='eager'
                      />
                      <div className='is-flex justify-center content'>
                        <p className='has-text-centered has-text-gold-black'>
                          {bullet.image.title}
                        </p>
                      </div>
                    </div>
                  </ScrollOnAnimation>
                </div>
              </div>
            </div>
            {(index === 0 || index === 2 || index === 5 || index === 7) && (
              <ScrollOnAnimation>
                <div className='hero-body has-text-centered content'>
                  <CTA />
                </div>
              </ScrollOnAnimation>
            )}
          </div>
        )
      )}
      <ScrollOnAnimation>
        <div className='hero'>
          <div className='hero-body'>
            <div className='container content'>
              <Testimony
                testimony={course.testimonials.testimonials[0]}
                expand={course.testimonials.expand}
                isBottomHR={false}
              />
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <ScrollOnAnimation>
        <div className='hero has-background-nude-lighter'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <FontAwesomeIcon
                icon={faAward}
                className='has-text-nude is-margin-bottom-4'
                size='2x'
              />
              <div className='is-margin-bottom-4'>
                <h3>{course.responsibility.title}</h3>
              </div>
              <p className='is-margin-bottom-6'>
                {course.responsibility.description}
              </p>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <ScrollOnAnimation>
        <div className='hero'>
          <div className='hero-body'>
            <div className='container content'>
              <div className='columns is-multiline is-mobile is-centered'>
                <div className='column is-12-mobile is-8-tablet is-6-desktop'>
                  <h4 className='has-text-centered has-text-weight-bold'>
                    {course.whoIsItFor.title}
                  </h4>
                  <p className='is-flex is-justify-content-center'>
                    {course.whoIsItFor.description}
                  </p>
                </div>
                <div className='column is-12-mobile is-8-tablet is-6-desktop'>
                  <h4 className='has-text-centered has-text-weight-bold'>
                    {course.whoIsItNotFor.title}
                  </h4>
                  <p className='is-flex is-justify-content-center'>
                    {course.whoIsItNotFor.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <ScrollOnAnimation>
        <div className='hero'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <h4>{course.whoIsItFor.suffix}</h4>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <ScrollOnAnimation>
        <div id='registration' className='hero has-background-nude-light'>
          <div className='hero-body'>
            <div className='container content'>
              <div className='columns is-centered'>
                <div className='column is-12-mobile is-9-tablet is-6-desktop'>
                  <p className='is-margin-bottom-6'>
                    <Markdown>{course.pricing.description}</Markdown>
                  </p>
                  <div className='has-text-centered'>
                    <button
                      className='button is-nude is-medium'
                      onClick={() => setIsPaymentForm(true)}
                    >
                      {course.pricing.callToAction}
                    </button>
                  </div>
                  <p className='help has-text-centered is-size-6 is-margin-top-6'>
                    {i18n.courses.paymentInfo.pci}
                  </p>
                  <p className='is-margin-top-6 has-text-centered is-italic'>
                    <Markdown>{course.pricing.talkToMeDescription}</Markdown>
                    <a
                      href='https://api.whatsapp.com/send?phone=972504998822&text=%D7%94%D7%99%D7%99%20%D7%A7%D7%90%D7%A8%D7%99%D7%9F%2C%20%D7%90%D7%A0%D7%99%20%D7%9E%D7%90%D7%95%D7%93%20%D7%A8%D7%95%D7%A6%D7%94%20%D7%9C%D7%94%D7%A6%D7%98%D7%A8%D7%A3%20%D7%9C%D7%A1%D7%93%D7%A0%D7%94%20*%D7%90%D7%99%D7%9A%20%D7%91%D7%90%D7%9E%D7%AA%20%D7%9C%D7%AA%D7%9E%D7%97%D7%A8%20%D7%A2%D7%95%D7%92%D7%95%D7%AA*%20%D7%95%D7%99%D7%A9%20%D7%9C%D7%99%20%D7%A9%D7%90%D7%9C%D7%94'
                      className='button is-text is-link-ka is-block'
                      style={{
                        padding: 0,
                        alignItems: 'unset',
                        border: 'unset'
                      }}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {course.pricing.talkToMeCTA}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      {course.testimonials && (
        <>
          <div className='hero'>
            <div className='hero-body'>
              <div className='container content'>
                <ScrollOnAnimation>
                  <div className='has-text-centered is-margin-bottom-6'>
                    <h3>{course.testimonials.title}</h3>
                  </div>
                </ScrollOnAnimation>
                {course.testimonials.testimonials
                  .slice(1)
                  .map((testimony, index) => (
                    <ScrollOnAnimation key={testimony.title}>
                      <>
                        <Testimony
                          testimony={testimony}
                          expand={course.testimonials.expand}
                          isBottomHR={
                            index < course.testimonials.testimonials.length - 2
                          }
                        />
                      </>
                    </ScrollOnAnimation>
                  ))}
              </div>
            </div>
          </div>
          <div className='hero'>
            <div className='hero-body has-text-centered content'>
              <CTA cta={course.callToActionOfficial} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

const HowToPriceCakes = ({ location: { pathname, search } }) => {
  const product = {
    category: course.greenInvoiceItemId,
    name: course.name,
    price: course.pricing.paymentPrice * course.pricing.numOfPayments
  }
  const data = useStaticQuery(HowToPriceCakesQuery)
  return (
    <Layout product={product}>
      <Seo
        metadata={course.metadata}
        pathname={pathname}
        og={{
          image: getSrc(data.image4),
          alt: course.mainImage.alt
        }}
        schemaMarkup={{
          breadcrumbs: [
            { name: i18n.header.academy, path: '/academy/' },
            {
              name: course.name,
              path: '/academy/how-to-price-cakes/'
            }
          ],
          product: {
            name: course.name,
            sku: course.name,
            description: course.title,
            brand: i18n.header.academy,
            image: getSrc(data.image4),
            path: '/academy/how-to-price-cakes/',
            price: (
              course.pricing.numOfPayments * course.pricing.paymentPrice
            ).toString()
          }
        }}
      />
      <HowToPriceCakesTemplate
        pathname={pathname}
        search={search}
        data={data}
        product={product}
      />
    </Layout>
  )
}

export default HowToPriceCakes
